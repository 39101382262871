import http from '../shared/http'
import apiResultWrapper from '../shared/apiResultWrapper'
// fetch
const fetchNotificationSettings = async (ownerId) => apiResultWrapper.apiResult(await http.get(`/api/v1/notifications/settings/${ownerId}`))
// change
const changeNotificationSettings = async (ownerId, body) => apiResultWrapper.apiResult(await http.post(`/api/v1/notifications/settings/${ownerId}/change`, body))

export default {
  fetchNotificationSettings,
  changeNotificationSettings
}
