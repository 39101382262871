<template>
  <v-container
    id="user-management"
    fluid
    tag="section"
  >
    <v-row>
      <v-col class="12">
        <v-tabs
          v-model="tab"
          fixed-tabs
        >
          <v-tab :disabled="isLoading">
            <span class="tab-header">User Info</span>
          </v-tab>
          <v-tab :disabled="isLoading">
            <span class="tab-header">Organization Structure</span>
          </v-tab>
          <v-tab :disabled="isLoading">
            <span class="tab-header">Notification Settings</span>
          </v-tab>
          <v-tab :disabled="isLoading">
            <span class="tab-header">Other Setting</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pb-1"
        >
          <v-tab-item>
            <v-form
              ref="form"
              class="ma-5"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.firstName"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="First Name"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.lastName"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="Last Name"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.username"
                    :disabled="true"
                    :rules="[rules.required, rules.min5]"
                    filled
                    label="Username"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.email"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min5]"
                    filled
                    label="Email"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.password"
                    :disabled="isLoading"
                    :rules="!!user.password || !!user.passwordRepeated ? [rules.required, rules.min5] : []"
                    filled
                    label="Password"
                    type="password"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.passwordRepeated"
                    :disabled="isLoading"
                    :rules="!!user.password || !!user.passwordRepeated ? [rules.required, rules.min5] : []"
                    filled
                    label="Repeat Password"
                    counter="64"
                    maxlength="64"
                    type="password"
                    clearable
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="user.permissions"
                    :items="permissions"
                    label="Choose permissions"
                    item-value="value"
                    :disabled="true"
                    item-text="label"
                    filled
                    multiple
                    chips
                    persistent-hint
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="createdAt"
                    :disabled="true"
                    filled
                    no-resize
                    full-width
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="updatedAt"
                    :disabled="true"
                    filled
                    no-resize
                    full-width
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="text-right"
                >
                  <v-btn
                    :disabled="isLoading"
                    color="primary"
                    class="white--text btn-center"
                    @click="saveUser()"
                  >
                    {{ 'Edit' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedDirectManagerAccessId"
                      class="ml-10 mr-10"
                      :disabled="true"
                      :items="availableDirectManager"
                      color="primary"
                      chips
                      auto-select-first
                      label="Direct Manager"
                      item-text="name"
                      item-value="accessId"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedManageByMe"
                      class="ml-10 mr-10"
                      :disabled="true"
                      :items="availableEmployees"
                      color="primary"
                      chips
                      multiple
                      auto-select-first
                      label="Manage by me"
                      item-text="name"
                      item-value="accessId"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              :headers="this.userPermissionTableHeader"
              :items="this.userNotificationSettings"
              :items-per-page="31"
              :loading="notificationSettingsLoading"
              :disable="notificationSettingsLoading"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:item.enable="{ item }">
                <v-simple-checkbox
                  v-model="item.enable"
                  :disable="notificationSettingsLoading"
                  @click="changeNotificationSetting(item)"
                />
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-form
              ref="userFeeForm"
              class="ma-5"
            >
              <v-row>
                <v-col cols="4">
                  <v-switch
                    v-model="otherSettings.blotterDuty"
                    :disabled="true"
                    :color="'red'"
                    inset
                    :label="`${otherSettings.blotterDuty ? 'Has blotter duty' : 'Not need to upload blotter'}`"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <div class="text-center">
        <v-dialog
          v-model="dialog.show"
          width="500"
        >
          <v-card>
            <v-card-title class="headline lighten-2">
              <span
                :class="`${dialog.error ? 'red--text' : 'blue--text'} text-uppercase`"
                style="letter-spacing: 0.3rem"
              > {{ dialog.title }} </span>
            </v-card-title>

            <v-card-text
              align="center"
            >
              {{ dialog.message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog.show = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import users from '@/shared/users'
import rules from '@/shared/rules'
import DateTimeService from '@/service/DateTimeService'
import UserNotificationSettings from '@/service/UserNotificationSettings'

export default {
  name: 'UserManagement',
  data: () => ({
    tab: 0,
    isLoading: false,
    dialog: {
      show: false,
      title: '',
      message: ''
    },
    user: {
      id: null,
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      permissions: [],
      password: '',
      passwordRepeated: '',
      locked: false,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime()
    },
    otherSettings: {
      blotterDuty: false
    },
    permissions: [
      { label: 'Dashboard', value: 'DASHBOARD' },
      { label: 'Sterling Reports', value: 'STERLING_REPORTS' },
      { label: 'Eze Reports', value: 'EZE_REPORTS' },
      { label: 'Support IT', value: 'SUPPORT_IT' },
      { label: 'Support Accounting', value: 'SUPPORT_ACCOUNTING' },
      { label: 'Support Management', value: 'SUPPORT_MANAGEMENT' },
      { label: 'Upload new blotter', value: 'BLOTTER_UPLOAD' },
      { label: 'Bonus Statement', value: 'MONTHLY_STATEMENT' },
      { label: 'Blotter Manger', value: 'BLOTTER_MANAGER' },
      { label: 'MNDB Tool Preview', value: 'MNDB_TOOL_TERMINAL' },
      { label: 'Library', value: 'LIBRARY_PREVIEW' },
      { label: 'Library Admin', value: 'LIBRARY_ADMIN' },
      { label: 'Dashboard Admin', value: 'DASHBOARD_ADMIN' },
      { label: 'Blotter Owner/Admin', value: 'BLOTTER_ADMIN' },
      { label: 'User Management', value: 'LIB_ACCESS_MODIFY' },
      { label: 'Sterling Reports Administration', value: 'STERLING_REPORTS_ADMIN' },
      { label: 'Eze Reports Administration', value: 'EZE_REPORTS_ADMIN' },
      { label: 'Bonus Statement Administration', value: 'MANAGE_MONTHLY_STATEMENT' },
      { label: 'Manage System Settings', value: 'MANAGE_SYSTEM_SETTINGS' },
      { label: 'View System Versions', value: 'SYSTEM_VERSIONS' }
    ],
    rules: rules,
    selectedDirectManagerAccessId: null,
    availableDirectManager: [],
    selectedManageByMe: null,
    availableEmployees: [],
    userNotificationSettings: [],
    userPermissionTableHeader: [
      { text: 'Type', value: 'type' },
      { text: 'Description', value: 'description' },
      { text: 'Enable', value: 'enable' }
    ],
    notificationSettingsLoading: false
  }),
  computed: {
    createdAt: function () {
      return `Created At: ${DateTimeService.format(new Date(this.user.createdAt))}`
    },
    updatedAt: function () {
      return `Last Update At: ${DateTimeService.format(new Date(this.user.updatedAt))}`
    }
  },
  beforeMount () {
    this.fetchUser()
    this.fetchAvailableAccounts()
    this.userNotificationSettingsFetch()
  },
  getters: {
    ...mapState(['user'])
  },
  methods: {
    async saveUser () {
      if (this.$refs.form.validate()) {
        if (this.user.password !== this.user.passwordRepeated) {
          // password does not match
          this.dialog.show = true
          this.dialog.title = 'Error'
          this.dialog.error = true
          this.dialog.message = 'Entered passwords does not match !'
          return
        }

        this.isLoading = true
        const response = await users.editSelf(this.user)
        if (response.status === 200) {
          this.user = response.data
          this.user.password = ''
          this.user.passwordRepeated = ''
          this.user.permissions = this.user.permissions || []
          this.dialog.show = true
          this.dialog.title = 'Success'
          this.dialog.error = false
          this.dialog.message = `Successfully edit user ${this.user.username} !`
        } else {
          this.dialog.show = true
          this.dialog.title = 'Error'
          this.dialog.error = true
          this.dialog.message = response.error.message
        }
        this.isLoading = false
      }
    },
    async fetchAvailableAccounts () {
      this.isLoading = true
      let response = await users.listAvailableManager()
      if (response.status === 200) {
        this.availableDirectManager = response.data
      }
      response = await users.listAvailableEmployees()
      if (response.status === 200) {
        this.availableEmployees = response.data
      }
      this.isLoading = false
    },
    async fetchUser () {
      this.isLoading = true
      let response = await users.userSelf()
      if (response.status === 200) {
        this.user = response.data
        this.user.password = ''
        this.user.passwordRepeated = ''
      }
      response = await users.userOrganizationStructure(this.user.id)
      if (response.status === 200) {
        this.selectedDirectManagerAccessId = response.data.directManager
        this.selectedManageByMe = response.data.manageByMe
      }
      response = await users.fetchSettings(this.user.id)
      if (response.status === 200) {
        this.otherSettings.blotterDuty = response.data.blotterDuty
      }
      this.isLoading = false
    },
    async userNotificationSettingsFetch () {
      this.isLoading = true
      const response = await UserNotificationSettings.fetchNotificationSettings(this.$store.getters.user.accessId)
      if (response.status === 200) {
        this.userNotificationSettings = response.data
      }
      this.isLoading = false
    },
    async changeNotificationSetting (item) {
      this.notificationSettingsLoading = true
      const response = await UserNotificationSettings.changeNotificationSettings(this.$store.getters.user.accessId, item)
      if (response.status === 200) {
        await this.userNotificationSettingsFetch()
      }
      this.notificationSettingsLoading = false
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}
</style>
